import React from "react";
import Helmet from "react-helmet";

export const SiteMetadata: React.FC = () => {
  return (
    <Helmet
      defaultTitle="Brianzarredi - Negozio di arredamenti a Milano sud - via Lorenteggio, piazza Frattini"
      titleTemplate="%s | Brianzarredi"
    >
      <script
        id="cookieyes"
        type="text/javascript"
        src="https://app.cookieyes.com/client_data/9d2f4bae69867031d64bc076.js"
      ></script>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};
