import { graphql, useStaticQuery } from "gatsby";
import { AllWorksCategoryQuery } from "../../graphql-types";

export type WorksCategory = AllWorksCategoryQuery["allDatoCmsWorksCategory"]["nodes"][number];

export const useWorksCategories = (): WorksCategory[] => {
  const result = useStaticQuery<AllWorksCategoryQuery>(graphql`
    query AllWorksCategory {
      allDatoCmsWorksCategory(sort: { fields: [position] }) {
        nodes {
          title
          slug
        }
      }
    }
  `);
  return result.allDatoCmsWorksCategory.nodes;
};
