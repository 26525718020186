import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import styled from "styled-components";
import { useProductCategories } from "../shared/use-product-categories";
import { useWorksCategories } from "../shared/use-works-categories";
import "./header.css";
import { Logo } from "./Logo";

const NavbarBrand = styled(Navbar.Brand)`
  &&& {
    height: 50px;
    padding: 0 0 0 60px;
    background: no-repeat transparent url(/logo_bg.svg) right / cover;
    margin-right: 0;

    @media (max-width: 991px) {
      padding: 0 0 0 20px;
    }
  }
`;

const Toggle = styled(Navbar.Toggle)`
  &&& {
    border-width: 0;
    padding: 5px 10px;
    color: #fff;

    &:focus {
      outline: none;
    }
  }
`;

export interface HeaderProps {
  pageScrolled: boolean;
}

export const Header: React.FC<HeaderProps> = props => {
  const productCategories = useProductCategories();
  const worksCategories = useWorksCategories();
  const headerClass = props.pageScrolled ? "header-scrolled" : "";

  return (
    <Navbar
      className={headerClass}
      collapseOnSelect
      expand="md"
      variant="dark"
      fixed="top"
    >
      <NavbarBrand>
        <Link to="/">
          <Logo />
        </Link>
      </NavbarBrand>
      <Toggle aria-controls="responsive-navbar-nav">
        <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
      </Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link to="/" activeClassName="active">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/servizi" activeClassName="active">
              Servizi
            </Link>
          </li>
          <li className="nav-item">
            <NavDropdown title="Prodotti" id="collasible-nav-dropdown">
              {productCategories.map(category => (
                <Link
                  to={`/prodotti/${category.slug}`}
                  key={category.slug!}
                  className="dropdown-item"
                >
                  {category.title}
                </Link>
              ))}
            </NavDropdown>
          </li>
          <li className="nav-item">
            <NavDropdown title="Lavori" id="collasible-nav-dropdown">
              {worksCategories.map(category => (
                <Link
                  to={`/lavori/${category.slug}`}
                  key={category.slug!}
                  className="dropdown-item"
                >
                  {category.title}
                </Link>
              ))}
            </NavDropdown>
          </li>
          <li className="nav-item">
            <Link to="/dove-siamo" activeClassName="active">
              Dove siamo
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contatti" activeClassName="active">
              Contatti
            </Link>
          </li>
        </ul>
      </Navbar.Collapse>
    </Navbar>
  );
};
