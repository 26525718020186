import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const StyledSection = styled.section`
  width: 100%;
  height: 300px;
  background: #000;
  position: relative;
  z-index: -2;
`;

const TextContainer = styled.div`
  text-align: center;
  padding-top: 130px;
  height: 100%;
  color: #fff;
  z-index: 1;

  h1 {
    font-size: 48px;
    font-weight: 700;
  }

  p {
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 32px;
      margin-left: 15px;
      margin-right: 15px;
    }

    p {
      font-size: 16px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
`;

interface IntroProps {
  title: string;
  tagLine?: string;
}

export const Intro: React.FC<IntroProps> = (props) => {
  return (
    <StyledSection>
      <ImageContainer>
        <StaticImage
          src="../images/splash1.jpg"
          alt=""
          style={{ height: "300px" }}
          objectFit="cover"
        />
      </ImageContainer>
      <TextContainer>
        <h1>{props.title}</h1>
        {props.tagLine ? <p>{props.tagLine}</p> : null}
      </TextContainer>
    </StyledSection>
  );
};
