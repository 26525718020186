import { graphql, useStaticQuery } from "gatsby";
import { AllCategoriesQuery } from "../../graphql-types";

export type ProductCategory =
  AllCategoriesQuery["allDatoCmsProductCategory"]["nodes"][number];

export const useProductCategories = (): ProductCategory[] => {
  const result = useStaticQuery<AllCategoriesQuery>(graphql`
    query AllCategories {
      allDatoCmsProductCategory(sort: { fields: [position] }) {
        nodes {
          title
          slug
          coverImage {
            gatsbyImageData(width: 500, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  return result.allDatoCmsProductCategory.nodes;
};
