import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Logo } from "./Logo";

const FooterInfoTitle = styled.h3`
  margin: 0 0 20px 0;
  padding: 2px 0 2px 10px;
  border-left: 4px solid #18d26e;
`;

const footerLinks: Record<string, string> = {
  "/": "Home",
  "/servizi/": "Servizi",
  "/dove-siamo/": "Dove siamo",
  "/privacy-policy/": "Privacy Policy",
};

export const Footer: React.FC = () => (
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <FooterInfoTitle>
              <Logo />
            </FooterInfoTitle>
            <p>
              <strong>Brianzarredi s.n.c.</strong>
              <br /> di Origgi Amedeo, Fabio e Gabriele
            </p>
            <p>
              <strong>Partita IVA:</strong> 00784190969
            </p>
          </div>

          <div className="col-lg-4 col-md-6 footer-links">
            <h4>Link Utili</h4>
            <ul>
              {Object.entries(footerLinks).map(([url, title]) => (
                <li key={url}>
                  <FontAwesomeIcon icon={faChevronRight} fixedWidth />{" "}
                  <Link to={url}>{title}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-lg-4 col-md-6">
            <h4>Contatti</h4>
            <p>
              Via Lorenteggio, 72 <br />
              20146 Milano
              <br />
            </p>
            <p>
              <strong>Telefono/Fax:</strong>{" "}
              <a href="tel:+390248301679">+39 02 48 30 16 79</a>
              <br />
              <strong>Email:</strong>{" "}
              <a href="mailto:brianzarredi@libero.it">brianzarredi@libero.it</a>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong>Brianzarredi</strong>. All Rights Reserved
      </div>
      <div className="credits">
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>
  </footer>
);
