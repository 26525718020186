import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowScroll from "@react-hook/window-scroll";
import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { myTheme } from "../theme";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./layout.css";
import { SiteMetadata } from "./SiteMetadata";

const BackToTop = styled.a`
  position: fixed;
  background: #13a456;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: all 0.5s;
  z-index: 11;

  &:hover {
    color: #fff;
  }
`;

export interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = props => {
  const scrollY = useWindowScroll();
  const pageScrolled = scrollY > 30;

  return (
    <ThemeProvider theme={myTheme}>
      <SiteMetadata></SiteMetadata>
      <Header pageScrolled={pageScrolled}></Header>
      <main id="main">{props.children}</main>
      <Footer></Footer>
      {pageScrolled && (
        <BackToTop href="#">
          <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
        </BackToTop>
      )}
    </ThemeProvider>
  );
};
